<template>
  <div>
    <form-layout ref="formLayout" :column="formColumn" :dataForm.sync="data"></form-layout>
  </div>
</template>
<script>
import ServerNameEnum from "@/util/ServerNameEnum";
import FormLayout from "@/views/components/layout/form-layout";
import {dateFormat} from "@/util/date";
import {mapGetters} from "vuex";

export default {
  components: {
    FormLayout
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    formColumn() {
      return [
        {
          label: "作业票扫描件",
          prop: "ticketFile",
          span: 24,
          labelWidth: '170',
          disabled: !this.disabled,
          listType: "picture-card",
          type: "upload",
          accept: 'image/png, image/jpeg',
          tip: '只能上传jpg/png的图片',
          action: `${ServerNameEnum.SERVER_RESOURCE_}/oss/endpoint/put-file`,
          propsHttp: {
            res: "data",
            url: "link",
          },
        },
      ]
    }
  },
  data() {
    return {
      data: {
        ticketFile: ""
      },
    };
  },
};
</script>
